import React, { useMemo, useEffect, useState, FormEvent, useRef } from "react";
import io from "socket.io-client";
import { AnimatePresence, motion } from "framer-motion";

interface IChatMessage {
  username: string;
  text: string;
}

const Chat = () => {
  const socket = useMemo(() => io("https://chat-server-bosch-es.imascono.com"), []);
  const [joined, setJoined] = useState(false);
  const [username, setUsername] = useState("");
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const latestMessageScrollRef = useRef<HTMLLIElement>(null);
  const emojiList = ["👍", "👎", "👏", "😄", "❤️"];

  useEffect(() => {
    // socket.on("newUser", (username: string) => {
    //   console.log(`New user: ${username}`);
    // });

    socket.on("historyMessages", (msg: any) => {
      setMessages((m) => [...m, ...msg]);
    });

    socket.on("chatMessage", (msg: IChatMessage) => {
      setMessages((m) => [...m, msg]);
    });
  }, [socket]);

  useEffect(() => {
    latestMessageScrollRef.current?.scrollIntoView();
  }, [messages]);

  const joinChat = (ev: FormEvent) => {
    ev.preventDefault();
    if (!username.trim()) return;

    setJoined(true);
    socket.emit("newUser", username);
  };

  const addEmojiToMessage = (emoji: string) => {
    setCurrentMessage((m) => m + emoji);
  };

  const sendMessage = (ev: FormEvent) => {
    ev.preventDefault();
    if (!currentMessage) return;
    socket.emit("chatMessage", currentMessage);
    setCurrentMessage("");
  };

  return (
    <div className="flex flex-col h-screen justify-between bg-blue-100">
      <main className="mb-auto bg-blue-100 pb-20">
        {messages.length > 0 && (
          <ul className="mb-2 p-1 overflow-y-auto overflow-x-hidden">
            <AnimatePresence>
              {messages.map((m, i) => (
                <motion.li
                  key={i}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <span
                    className="user_chat font-bold"
                    style={{ color: "#37576e" }}
                  >
                    {m.username}:
                  </span>{" "}
                  {m.text}
                </motion.li>
              ))}
            </AnimatePresence>
            <li ref={latestMessageScrollRef}></li>
          </ul>
        )}
      </main>
      <footer className="fixed inset-x-0 bottom-0 bg-white">
        {joined ? (
          <>
            <ul className="flex text-xl mb-2">
              {emojiList.map((e) => (
                <li
                  key={e}
                  className="mr-2 cursor-pointer select-none"
                  onClick={() => addEmojiToMessage(e)}
                >
                  {e}
                </li>
              ))}
            </ul>
            <form onSubmit={sendMessage} className="border-2">
              <input
                autoFocus={true}
                key="currentMessage"
                placeholder="Escribe en el chat..."
                value={currentMessage}
                className="w-4/6 px-2 bg-transparent"
                onChange={(ev) => setCurrentMessage(ev.currentTarget.value)}
              />

              <button
                type="submit"
                className="w-2/6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                <span className="full-text">Enviar</span>
              </button>
            </form>
          </>
        ) : (
          <form onSubmit={joinChat} className="border-2">
            <input
              key="username"
              placeholder="Introduce tu nombre para chatear"
              value={username}
              className="w-4/6 px-2 bg-transparent"
              onChange={(ev) => setUsername(ev.currentTarget.value)}
            />

            <button
              type="submit"
              className="w-2/6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              <span className="full-text">Unirse</span>
            </button>
          </form>
        )}
      </footer>
    </div>
  );
};

export default Chat;
